/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Spinner,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import HookHelper from '../../../helper/hookhelper'
import Formatter from '../../../helper/formatter'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import LastTrxDialog from '../../dialog/lastTransaction'
import EmoneyModal from '../../dialog/layanan/emoney'

import { emoneyProduk, emoneyPay } from '../../../api/action/layanan/emoney'
import { lastTransaction } from '../../../api/action/global'

const EmoneyScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const formGameRef = useRef(null)
  const btnNextRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [emoneyModalShow, setEmoneyModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [lastTrx, setLastTransaction] = React.useState([])
  const [lastTrxFiltered, setLastTransactionFiltered] = React.useState([])
  const [lastTrxShow, setLastTrxShow] = React.useState(false)
  const [searchLastTrx, setSearchLastTrx] = React.useState('')
  const [dataGroupProduk, setDataGroupproduk] = React.useState([])
  const [product, setProduct] = React.useState(null)
  const [provider, setProvider] = React.useState(null)
  const [providerProduct, setProviderProduct] = React.useState([])
  const [banner, setBanner] = React.useState('')
  const [customer, setCustomer] = React.useState('')
  const [discount] = React.useState('0')

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [setLoginModalShow])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [setLoginModalShow])

  const closeEmoneyModal = React.useCallback(async () => {
    setEmoneyModalShow(false)
  }, [setEmoneyModalShow])

  const openLastTransaction = React.useCallback(async () => {
    setLastTrxShow(true)
  }, [setLastTrxShow])

  const closeLastTransaction = React.useCallback(async () => {
    setLastTrxShow(false)
  }, [setLastTrxShow])

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }
      setIsInqWeb(false)
    },
    [setCustomer, setIsInqWeb]
  )

  const selectProvider = React.useCallback(
    async (e) => {
      setLastTransaction([])
      setLastTransactionFiltered([])
      const { id } = e.currentTarget.dataset
      const providerId = parseInt(id, 10)

      if (providerId === 113) {
        history.push('/emoney/top-up-dana')
      } else if (providerId === 114) {
        history.push('/emoney/top-up-shopeepay')
      } else {
        const [filtered] = dataGroupProduk.filter((g) => `${g.provider_id}` === id)
        _.orderBy(filtered, ['is_gangguan', 'urutan'], ['asc', 'asc'])
        setCustomer('')
        setIsInqWeb(false)
        setProvider(filtered)
        setProviderProduct(filtered.products)

        if (filtered.banner) {
          setBanner(filtered.banner)
        } else if (filtered.image) {
          setBanner(filtered.image)
        }

        await dispatch(lastTransaction(userId, '', '1', id)).then((response) => {
          if (response.isOK()) {
            setLastTransaction(response.getResultAsList())
            setLastTransactionFiltered(response.getResultAsList())
          }
        })

        // eslint-disable-next-line no-unused-expressions
        formGameRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    },
    [
      dispatch,
      history,
      userId,
      dataGroupProduk,
      setCustomer,
      setProvider,
      setProviderProduct,
      setIsInqWeb,
      setBanner,
      setLastTransaction,
      setLastTransactionFiltered,
    ]
  )

  const selectProduct = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      const [filteredProduct] = providerProduct.filter((g) => `${g.idproduk}` === id)
      if (filteredProduct.is_gangguan === '0') {
        setProduct(filteredProduct)

        // eslint-disable-next-line no-unused-expressions
        btnNextRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else {
        setAlertError({
          rc: '',
          rd: 'Produk yang Anda pilih sedang gangguan. Harap pilih produk yang lain.',
        })
      }
      setIsInqWeb(false)
    },
    [providerProduct, setProduct, setAlertError, setIsInqWeb]
  )

  const onCustomerChange = React.useCallback(
    (e) => {
      setCustomer(e.target.value)
    },
    [setCustomer]
  )

  const confirmLastTrx = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      setCustomer(target)
      setLastTrxShow(false)
    },
    [setCustomer, setLastTrxShow]
  )

  const onSearchLastTrxChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(lastTrx, (item) => {
        return (
          _.includes(item.id_pelanggan, value) ||
          _.includes(item.nama_pelanggan.toLowerCase(), value.toLowerCase())
        )
      })

      setSearchLastTrx(value)
      setLastTransactionFiltered(newData)
    },
    [lastTrx, setSearchLastTrx, setLastTransactionFiltered]
  )

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const proccessGame = React.useCallback(async () => {
    if (!customer) {
      setAlertError({ rc: '', rd: 'User ID / Nomor HP harap diisi terlebih dahulu' })
      return false
    }

    if (!product) {
      setAlertError({ rc: '', rd: 'Harap pilih produk terlebih dahulu' })
      return false
    }

    if (Math.round(parseFloat(appUserBalance)) >= Math.round(parseFloat(product.hargajual))) {
      setIsSaldoOk(true)
    }

    // eslint-disable-next-line no-unused-expressions
    formGameRef.current?.scrollIntoView({ behavior: 'smooth' })

    if (isMobile) {
      setEmoneyModalShow(true)
    } else {
      setIsInqWeb(true)
    }
    return true
  }, [
    appUserBalance,
    customer,
    product,
    setEmoneyModalShow,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const confirmPayment = React.useCallback(async () => {
    if (!appUserId) {
      setEmoneyModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const response = await dispatch(
        emoneyPay(userId, userPin, customer, discount, product.idproduk)
      )
      if (response.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'emoney',
            parentPath: location.pathname,
            title: 'Top Up E-Money',
            icon: 'wallet',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        response.getResponseCode() === '' ||
        response.getRC() === '68' ||
        response.getRC().toString() === response.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'emoney',
            parentPath: location.pathname,
            title: 'Top Up E-Money',
            icon: 'wallet',
            transaksi: response.data.includes.reff_id,
            trxDate: response.data.transmission_datetime,
            trxTime: response.data.transmission_datetime,
            total: response.data.includes.amount,
            customerId: response.data.includes.customer_id_1,
            discountInfo: response.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    appUserId,
    userId,
    userPin,
    customer,
    discount,
    product,
    history,
    location,
    isSaldoOk,
    setEmoneyModalShow,
    setLoginModalShow,
    redirectToDeposit,
    setAlertError,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    dispatch(emoneyProduk(userId)).then((response) => {
      if (response.isOK()) {
        const responseData = response.getResultAsObject()

        const sortResData = _.orderBy(
          responseData,
          ['game_topup', 'prioritas', 'provider'],
          ['desc', 'asc', 'asc']
        )
        setDataGroupproduk(sortResData)

        const providerId = location?.state?.provider_id

        if (providerId) {
          const [providerEmoney] = sortResData.filter((g) => g.provider_id === providerId)
          if (providerEmoney) {
            setProvider(providerEmoney)
            setProviderProduct(providerEmoney.products)

            if (providerEmoney.banner) {
              setBanner(providerEmoney.banner)
            } else if (providerEmoney.image) {
              setBanner(providerEmoney.image)
            }

            dispatch(lastTransaction(userId, '', '1', providerEmoney.provider_id)).then((res) => {
              if (res.isOK()) {
                setLastTransaction(res.getResultAsList())
                setLastTransactionFiltered(res.getResultAsList())
              }
            })
          }
        }
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      }
    })

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [dispatch, userId, location, setDataGroupproduk, setAlertError])

  return (
    <Container fluid className="layanan-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Top Up E-Money" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                    Top Up E-Money
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>
          <div className="content-layanan" ref={formGameRef}>
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                {provider && (
                  <Row>
                    <Form className={classNames('form-pelanggan', { 'is-mobile': isMobile })}>
                      <Form.Group>
                        <Form.Label
                          className="form-label"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>User ID / Nomor HP</span>
                          <span className="color-primary">{provider.provider}</span>
                        </Form.Label>
                        <InputGroup className="mb-12">
                          <Form.Control
                            placeholder="Cth: 123456789"
                            name="customer"
                            value={customer}
                            onChange={onCustomerChange}
                            disabled={isInqWeb || emoneyModalShow}
                          />
                          <InputGroup.Append className="input-group-game">
                            <Image
                              width={48}
                              height={48}
                              src={banner}
                              rounded
                              fluid
                              style={{ marginRight: '5px' }}
                            />
                            <Image
                              src="/icon/backspace.png"
                              data-target="customer"
                              onClick={handleClear}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>

                      <Row>
                        {providerProduct.map((value) => (
                          <Col
                            className="icon-col-game produk pointing"
                            xs={6}
                            md={4}
                            lg={3}
                            key={value.idproduk}
                            data-id={value.idproduk}
                            onClick={selectProduct}
                          >
                            <div
                              className={classNames('col-list-game produk pointing', {
                                trouble: value.is_gangguan === '1',
                                'selected-produk':
                                  value.idproduk === (product ? product.idproduk : ''),
                              })}
                            >
                              <div className="produk-body">
                                <span
                                  className={classNames('produk-label', {
                                    trouble: value.is_gangguan === '1',
                                    'is-mobile': isMobile,
                                    'selected-produk':
                                      value.idproduk === (product ? product.idproduk : ''),
                                  })}
                                >
                                  {Math.round(value.denom) > 0
                                    ? Formatter.price(Math.round(value.denom))
                                    : Formatter.price(Math.round(value.hargajual))}
                                </span>
                                <span
                                  className={classNames('produk-sub-label', {
                                    trouble: value.is_gangguan === '1',
                                    'is-mobile': isMobile,
                                    'selected-produk':
                                      value.idproduk === (product ? product.idproduk : ''),
                                  })}
                                >
                                  {value.namaproduk}
                                </span>
                                {Math.round(value.nominalUp) > 0 && (
                                  <span
                                    className={classNames(
                                      'produk-sub-label color-primary txt-bold',
                                      {
                                        trouble: value.is_gangguan === '1',
                                        'is-mobile': isMobile,
                                        'selected-produk':
                                          value.idproduk === (product ? product.idproduk : ''),
                                      }
                                    )}
                                  >
                                    {`Diskon Rp. ${Formatter.price(Math.round(value.nominalUp))}`}
                                  </span>
                                )}
                              </div>
                              <div
                                className={classNames('produk-footer', {
                                  trouble: value.is_gangguan === '1',
                                  'selected-produk':
                                    value.idproduk === (product ? product.idproduk : ''),
                                })}
                              >
                                {/* <span
                                  className={classNames('produk-label-footer', {
                                    'is-mobile': isMobile || isInqWeb,
                                  })}
                                >
                                  Harga Rp.{' '}
                                </span> */}
                                {Math.round(value.nominalUp) > 0 &&
                                  Math.round(value.hargajual) > 0 && (
                                    <span
                                      className={classNames('produk-label-footer', {
                                        'is-mobile': isMobile || isInqWeb,
                                        'is-diskon': true,
                                      })}
                                    >
                                      {`Rp ${Formatter.price(
                                        Math.round(value.hargajual) + Math.round(value.nominalUp)
                                      )}`}
                                    </span>
                                  )}
                                <span
                                  className={classNames('produk-label-footer', {
                                    'is-mobile': isMobile || isInqWeb,
                                  })}
                                >
                                  {value.hargajual > 0
                                    ? `Rp ${Formatter.price(Math.round(value.hargajual))}`
                                    : ' '}
                                </span>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Form>
                    <Col
                      ref={btnNextRef}
                      style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}
                    >
                      <Button
                        variant="warning"
                        className="btn-next"
                        size="lg"
                        onClick={proccessGame}
                        disabled={appIsLoading}
                        style={{ textTransform: 'none', width: '50%' }}
                      >
                        {appIsLoading && !isMobile && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                      </Button>
                    </Col>

                    <hr />
                  </Row>
                )}
                {lastTrx.length > 0 && appUserId && (
                  <div className="form-last-trx">
                    <span className="form-label">Transaksi Terakhir</span>
                    <Row
                      style={{
                        border: '1px solid #DDDDDD',
                        borderRadius: '10px',
                        alignItems: 'center',
                        padding: '10px',
                      }}
                    >
                      <Col lg={1} xs={2} md={1}>
                        <Image src={lastTrx[0].image_url} width={36} />
                      </Col>
                      <Col
                        lg={8}
                        xs={10}
                        md={8}
                        data-target={lastTrx[0].id_pelanggan}
                        onClick={confirmLastTrx}
                        className="pointing"
                      >
                        <Row>
                          <span className="form-label-main">{lastTrx[0].id_pelanggan}</span>
                        </Row>
                        {lastTrx[0].id_pelanggan !== lastTrx[0].nama_pelanggan ? (
                          <Row>
                            <span className="label-text">{lastTrx[0].nama_pelanggan}</span>
                          </Row>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col lg={3} xs={8} md={3} onClick={openLastTransaction}>
                        <div className="label-text color-primary pointing">
                          Lihat riwayat transaksi
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row style={{ padding: '20px' }} className="justify-content-md-center">
                  {dataGroupProduk.map((item) => (
                    <Col
                      className="cl-list-emoney pointing"
                      xs={6}
                      md={4}
                      lg={dataGroupProduk.length < 3 ? 6 : 3}
                      key={item.provider_id}
                      data-id={item.provider_id}
                      onClick={selectProvider}
                    >
                      <div
                        className={classNames('ct-list-emoney', {
                          'selected-produk':
                            item.provider_id === (provider ? provider.provider_id : ''),
                        })}
                      >
                        <Row className="justify-content-md-center row-ct-list-emoney">
                          <Col>
                            {item.banner ||
                              (item.image && (
                                <Image
                                  className="image-logo"
                                  src={item.banner ? item.banner : item.image}
                                  fluid
                                />
                              ))}
                            {!item.banner && !item.image && (
                              <div
                                className="justify-content-md-center"
                                style={{ textAlign: 'center' }}
                              >
                                <FontAwesomeIcon
                                  icon="wallet"
                                  fixedWidth
                                  style={{ fontSize: '36px' }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center row-provider">
                          <Col className="subcol-label">{item.provider}</Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq">
                    <Row className="justify-content-md-center">
                      <Image className="image-logo" src={banner} rounded fluid />
                    </Row>
                    <Row>
                      <Col>Nama Provider</Col>
                      <Col className="inq-end-label">{provider ? provider.provider : ''}</Col>
                    </Row>
                    <Row>
                      <Col>Nama Produk</Col>
                      <Col className="inq-end-label">{product ? product.namaproduk : ''}</Col>
                    </Row>
                    <Row>
                      <Col>ID Tujuan</Col>
                      <Col className="inq-end-label">{customer || ''}</Col>
                    </Row>
                    <Row>
                      <Col>Harga</Col>
                      <Col className="inq-end-label">
                        {product
                          ? `Rp. ${Formatter.price(
                              Math.round(product.hargajual) + Math.round(product.nominalUp)
                            )}`
                          : ''}
                      </Col>
                    </Row>
                    {product && Math.round(product.nominalUp) > 0 && (
                      <Row>
                        <Col>Diskon</Col>
                        <Col className="inq-end-label color-primary txt-bold">
                          {product ? `-Rp. ${Formatter.price(Math.round(product.nominalUp))}` : ''}
                        </Col>
                      </Row>
                    )}
                    <Row className="inq-body-last">
                      <Col>Total Bayar</Col>
                      <Col className="inq-end-label txt-bold">
                        {product
                          ? `Rp. ${Formatter.price(parseFloat(product.hargajual))}`
                          : 'Rp. 0'}
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center inq-body-last">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {appUserBalance
                              ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!isSaldoOk && appUserId && (
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance not-ok">
                          <Row className="txt-bold">
                            Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={
                            appUserId
                              ? isSaldoOk
                                ? confirmPayment
                                : redirectToDeposit
                              : openLoginModal
                          }
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? isSaldoOk
                              ? 'Bayar Sekarang'
                              : 'Top Up Saldo'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <LastTrxDialog
        show={!!lastTrxShow}
        onHide={closeLastTransaction}
        onConfirm={confirmLastTrx}
        search={searchLastTrx}
        onSearchChange={onSearchLastTrxChange}
        data={lastTrxFiltered}
      />
      <EmoneyModal
        show={!!emoneyModalShow}
        onHide={closeEmoneyModal}
        onConfirm={confirmPayment}
        customer={customer}
        provider={provider}
        product={product}
        banner={banner}
        isSaldoOk={isSaldoOk}
      />
    </Container>
  )
}

EmoneyScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

EmoneyScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(EmoneyScreen)
