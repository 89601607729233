/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import {
  Spinner,
  Form,
  Image,
  Container,
  Jumbotron,
  InputGroup,
  Button,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './transfer.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import TransferSaldoModal from '../../dialog/transfer/saldo'

import { checkMember, transferPay } from '../../../api/action/transfer/saldo'

const TransferSaldoScreen = ({
  appUserId,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [saldoModalShow, setSaldoLoginModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin, setUserPin] = React.useState('')
  const [customer, setCustomer] = React.useState('')
  const [nominal, setNominal] = React.useState('0')
  const [berita, setBerita] = React.useState('')
  const [dataRes, setDataRes] = React.useState(null)
  const [isShowPin, setIsShowPin] = React.useState(false)

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }
      if (target === 'nominal') {
        setNominal(0)
      }
      if (target === 'berita') {
        setBerita('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCustomer, setNominal, setBerita, setIsInqWeb, setDataRes]
  )

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  // const redirectToDeposit = React.useCallback(() => {
  //   history.push('/deposit')
  // }, [history])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [setLoginModalShow])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeLoginModal = React.useCallback(async () => {
    if (!appUserId) {
      setLoginModalShow('')
      history.push('/')
    } else {
      setLoginModalShow('')
    }
  }, [appUserId, history, setLoginModalShow])

  const closeSaldoModal = React.useCallback(async () => {
    setSaldoLoginModalShow(false)
  }, [setSaldoLoginModalShow])

  const closeError = React.useCallback(async () => {
    // if (alertError) {
    //   if (alertError.rc === '06' || alertError.rc === 6) {
    //     redirectToDeposit()
    //   }
    // }

    setAlertError('')
  }, [setAlertError])

  const showPin = React.useCallback(async () => {
    // eslint-disable-next-line no-shadow
    setIsShowPin((isShowPin) => !isShowPin)
  }, [setIsShowPin])

  const onCustomerChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setCustomer(Formatter.number(value))
    },
    [setCustomer]
  )

  const onNominalChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setNominal(Formatter.number(value))
    },
    [setNominal]
  )

  const onBeritaChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setBerita(value)
    },
    [setBerita]
  )

  const onUserPinChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setUserPin(value)
    },
    [setUserPin]
  )

  const onProcess = React.useCallback(async () => {
    const userBalance = Math.round(parseFloat(appUserBalance))
    setUserPin('')

    if (!customer) {
      setAlertError({ rc: '', rd: 'Masukkan No HP Tujuan / ID SC Terlebih Dahulu' })
      setIsInqWeb(false)
      return false
    }

    if (parseInt(nominal, 10) <= 0) {
      setAlertError({ rc: '', rd: 'Nominal harus diatas 0' })
      setIsInqWeb(false)
      return false
    }

    if (parseInt(nominal, 10) > userBalance) {
      setAlertError({
        rc: '06',
        rd: 'Saldo anda tidak cukup untuk melakukan transaksi ini.',
      })
      setIsInqWeb(false)
      return false
    }

    const response = await dispatch(checkMember(userId, customer))

    if (response.isOK()) {
      const dataRespon = response.getData()
      setDataRes(dataRespon.data)

      if (!isMobile) {
        setIsInqWeb(true)
      } else {
        setSaldoLoginModalShow(true)
      }
    } else {
      if (response.getRD()) {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
      } else {
        const dataRespon = response.getData()
        setAlertError({
          rc: response.getRC(),
          rd:
            typeof dataRespon.data === 'string' ? dataRespon.data : 'Gagal Memeriksa ID/No Tujuan',
        })
      }

      return false
    }

    return true
  }, [
    dispatch,
    userId,
    appUserBalance,
    customer,
    nominal,
    setIsInqWeb,
    setDataRes,
    setUserPin,
    setAlertError,
    setSaldoLoginModalShow,
  ])

  const confirmTransfer = React.useCallback(async () => {
    if (!appUserId) {
      setIsInqWeb(false)
      setLoginModalShow(true)
    }

    if (!userPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN Terlebih Dahulu' })
    } else {
      const responsePay = await dispatch(transferPay(userId, userPin, customer, nominal, berita))
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'transfer/saldo',
            parentPath: location.pathname,
            title: 'Transfer Member',
            icon: 'exchange-alt',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.biller_info.nominal_nofr) +
              Number(responsePay.data.biller_info.admin_nofr),
            customerId: responsePay.data.biller_info.tujuanId,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getRC() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'transfer/saldo',
            parentPath: location.pathname,
            title: 'Transfer Member',
            icon: 'exchange-alt',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.biller_info.nominal_nofr) +
              Number(responsePay.data.biller_info.admin_nofr),
            customerId: responsePay.data.biller_info.tujuanId,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getResponseCode(), rd: responsePay.getRD() })
      }
    }
  }, [
    dispatch,
    history,
    location,
    appUserId,
    userId,
    userPin,
    customer,
    nominal,
    berita,
    setAlertError,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('transfer-layout')
    HookHelper.resetLoading()

    if (!appUserId) {
      setLoginModalShow(true)
    }

    return () => DOMHelper.unmountClass(['transfer-layout'])
  }, [appUserId, setLoginModalShow])

  return (
    <Container fluid className="transfer-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Transfer Member" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="exchange-alt" fixedWidth className="mr-3" />
                    Transfer Member
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          <div className="content-layanan">
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                <Form className="form-pelanggan">
                  <Form.Group>
                    <Form.Label className="form-label start">No. HP Tujuan / ID SC</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="No. HP Tujuan / ID SC"
                        name="customer"
                        value={Formatter.serial(customer)}
                        onChange={onCustomerChange}
                        disabled={isInqWeb || saldoModalShow}
                      />
                      <InputGroup.Append>
                        <Image
                          src="/icon/backspace.png"
                          data-target="customer"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Nominal Transfer</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Nominal Transfer"
                        value={Formatter.price(nominal)}
                        onChange={onNominalChange}
                        disabled={isInqWeb || saldoModalShow}
                      />
                      <InputGroup.Append>
                        <Image
                          src="/icon/backspace.png"
                          data-target="nominal"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Berita Transfer</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Cth: Tabungan Umroh Bulan Juli"
                        value={berita}
                        onChange={onBeritaChange}
                        disabled={isInqWeb || saldoModalShow}
                      />
                      <InputGroup.Append>
                        <Image
                          src="/icon/backspace.png"
                          data-target="berita"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Row className="justify-content-md-center">
                    <Col className="box-balance">
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col lg={1} xs={2} md={2}>
                          <Image src="/img/sc.png" roundedCircle />
                        </Col>
                        <Col lg={6} xs={5} md={5}>
                          Saldo Speedcash
                        </Col>
                        <Col lg={5} xs={5} md={5} className="text-end txt-bold">
                          {appUserBalance
                            ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                            : 'Rp 0,-'}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <Row className="row-proses">
                  <Col className="nominal-minimal" />
                  <Col style={{ margin: 'auto' }}>
                    <Button
                      className="btn-process"
                      size="lg"
                      block
                      onClick={onProcess}
                      disabled={appIsLoading}
                    >
                      {appIsLoading && !isMobile && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                    </Button>
                  </Col>
                </Row>
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq">
                    <Row className="justify-content-md-center">
                      <Col className="inq-header txt-bold">Konfirmasi Transfer</Col>
                    </Row>
                    <Row>
                      <Col>No. HP / ID Penerima</Col>
                      <Col className="inq-end-label">{customer}</Col>
                    </Row>
                    <Row>
                      <Col>Nama Penerima</Col>
                      <Col className="inq-end-label">{dataRes}</Col>
                    </Row>
                    <Row>
                      <Col>Nominal Transfer</Col>
                      <Col className="inq-end-label">Rp. {Formatter.price(nominal)}</Col>
                    </Row>
                    <Row>
                      <Col>Biaya Admin</Col>
                      <Col className="inq-end-label">Rp. 0</Col>
                    </Row>
                    <Row>
                      <Col>Total</Col>
                      <Col className="inq-end-label">Rp. {Formatter.price(nominal)}</Col>
                    </Row>
                    <Row>
                      <Col>Berita</Col>
                      <Col className="inq-end-label">{berita}</Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {appUserBalance
                              ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center inq-body-last">
                      <Form className="form-pelanggan">
                        <Form.Group>
                          <Form.Label
                            className="form-label text-center color-primary txt-16 txt-bold"
                            style={{ width: '100%' }}
                          >
                            Masukkan PIN
                          </Form.Label>
                          <InputGroup className="mb-12">
                            <Form.Control
                              type={isShowPin ? 'text' : 'password'}
                              className="text-center"
                              maxLength={6}
                              value={userPin}
                              onChange={onUserPinChange}
                            />
                            <InputGroup.Append
                              className="pointing"
                              style={{ alignItems: 'center' }}
                            >
                              <FontAwesomeIcon
                                icon={isShowPin ? 'eye-slash' : 'eye'}
                                data-target="pin"
                                onClick={showPin}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={appUserId ? confirmTransfer : openLoginModal}
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? 'Transfer Sekarang'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <TransferSaldoModal
        show={!!saldoModalShow}
        onHide={closeSaldoModal}
        onConfirm={confirmTransfer}
        dataRes={dataRes}
        customer={customer}
        nominal={nominal}
        berita={berita}
        pin={userPin}
        balance={appUserBalance}
        onUserPinChange={onUserPinChange}
        isShowPin={isShowPin}
        showPin={showPin}
      />
    </Container>
  )
}

TransferSaldoScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
}

TransferSaldoScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
  }),
  (dispatch) => ({ dispatch })
)(TransferSaldoScreen)
